import React, { useState, useEffect } from 'react';
// User context
import { useUsersContext } from '@src/contexts/users';
import { UsersActions } from '@src/contexts/users/types';
// Types
import type { UsersIntergrationType, ErrorObject } from './types';
import type { AxiosResponse } from 'axios';
// API
import { integrationByType, setAllUsersIntegration, testConnection } from '@src/Api';
// Styles
import { Row, Col } from 'react-bootstrap';
// Components
import RentmanForm from './rentman';
import RentmanInternalForm from './rentmanInternal';
import BigcommerceForm from './bigcommerce';
import { MailgunSubmitForm } from './mailgun';

const defaultConfig = {
  users_integrations_type: '',
  users_integrations_api_data: {},
  users_integrations_connected: 0,
};

export const UserConfigPage: React.FC = () => {
  const { usersState, usersDispatch } = useUsersContext();
  const [config, setConfig] = useState<UsersIntergrationType>({
    rentman: { ...defaultConfig, users_integrations_type: 'rentman', users_integrations_users_id: usersState.user.id },
    rentmanInternal: { ...defaultConfig, users_integrations_type: 'rentmanInternal', users_integrations_users_id: usersState.user.id },
    bigcommerce: { ...defaultConfig, users_integrations_type: 'bigcommerce', users_integrations_users_id: usersState.user.id },
    mailgun: { ...defaultConfig, users_integrations_type: 'mailgun', users_integrations_users_id: usersState.user.id },
  });
  const [statusText, setStatusText] = useState<{ [x: string]: string }>({
    rentman: '...',
    rentmanInternal: '...',
    bigcommerce: '...',
    mailgun: '...',
  });
  const [loading, setLoading] = useState<{ [x: string]: boolean }>({
    rentman: false,
    rentmanInternal: false,
    bigcommerce: false,
    mailgun: false,
  });
  const [errorObject, setErrorObject] = useState<ErrorObject>({
    rentman: null,
    rentmanInternal: null,
    bigcommerce: null,
    mailgun: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleResponse = async (response: AxiosResponse | null, type: 'rentman' | 'rentmanInternal' | 'bigcommerce' | 'mailgun'): Promise<void> => {
    if (response) {
      if (response.data && 'error' in response.data) {
        setStatusText({
          ...statusText,
          [type]: ` ${response.status}: ${response.data.status ? response.data.status : response.data.error}`,
        });
        setErrorObject({
          ...errorObject,
          [type]: response.data.error as object,
        });
      } else {
        setStatusText({
          [type]: ` ${response.status}: ${response.data.message}`,
        });
        setErrorObject({
          ...errorObject,
          [type]: null,
        });
      }

      if (response.data.integration) {
        setConfig((prevConfig) => ({
          ...prevConfig,
          [type]: response.data.integration,
        }));
      }

      if (response.data.token) {
        usersDispatch({ type: UsersActions.LOGIN, payload: response.data.token });
      }
    }
  };

  const handleMailgunResponse = async (response: AxiosResponse | null) => {
    if (response) {
      if (response.data && 'error' in response.data) {
        setStatusText({
          ...statusText,
          ['mailgun']: `${response.status} : ${response.data.status ? response.data.status : response.data.error}`,
        });
        setErrorObject({
          ...errorObject,
          ['mailgun']: response.data.error as object,
        });
      } else {
        setStatusText({
          ['mailgun']: `200: Mailgun successfully connected`,
        });
        setErrorObject({
          ...errorObject,
          ['mailgun']: null,
        });
      }

      if (response.data) {
        setConfig((prevConfig) => ({
          ...prevConfig,
          ['mailgun']: response.data,
        }));
      }
    }
  };

  useEffect(() => {
    const getIntegrationHandler = async (): Promise<void> => {
      const testRentmanConnectionResponse = await testConnection('rentman');
      await handleResponse(testRentmanConnectionResponse, 'rentman');

      const testRentmanInternalConnectionResponse = await testConnection('rentmanInternal');
      await handleResponse(testRentmanInternalConnectionResponse, 'rentmanInternal');

      const testBigcommerceConnectionResponse = await testConnection('bigcommerce');
      await handleResponse(testBigcommerceConnectionResponse, 'bigcommerce');

      const mailgunIntegration = await integrationByType('mailgun');
      await handleMailgunResponse(mailgunIntegration);
    };
    getIntegrationHandler();
  }, []);

  const handleRentmanValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      rentman: {
        ...config.rentman,
        users_integrations_api_data: {
          ...config.rentman.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleRentmanInternalValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      rentmanInternal: {
        ...config.rentmanInternal,
        users_integrations_api_data: {
          ...config.rentmanInternal.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleBigcommerceValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      bigcommerce: {
        ...config.bigcommerce,
        users_integrations_api_data: {
          ...config.bigcommerce.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleMailgunValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({
      ...config,
      mailgun: {
        ...config.mailgun,
        users_integrations_api_data: {
          ...config.mailgun.users_integrations_api_data,
          [event.target.id]: event.target.value,
        },
      },
    });
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>, type: 'rentman' | 'rentmanInternal' | 'bigcommerce' | 'mailgun'): Promise<void> => {
    e.preventDefault();

    setLoading({
      [type]: true,
    });

    const response = await setAllUsersIntegration(config, type);
    await handleResponse(response, type);

    setLoading({
      [type]: false,
    });

    setFormSubmitted(true);
  };

  return (
    <>
      <Row className="mt-4">
        <Col sm={6}>
          <RentmanForm
            config={config}
            loading={loading}
            statusText={statusText}
            errorObject={errorObject}
            formSubmitted={formSubmitted}
            handleRentmanValueChanged={handleRentmanValueChanged}
            handleFormSubmit={handleFormSubmit}
          />
        </Col>

        <Col sm={6}>
          <RentmanInternalForm
            config={config}
            loading={loading}
            statusText={statusText}
            errorObject={errorObject}
            formSubmitted={formSubmitted}
            handleRentmanValueChanged={handleRentmanInternalValueChanged}
            handleFormSubmit={handleFormSubmit}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={6}>
          <BigcommerceForm
            config={config}
            loading={loading}
            statusText={statusText}
            errorObject={errorObject}
            formSubmitted={formSubmitted}
            handleBigcommerceValueChanged={handleBigcommerceValueChanged}
            handleFormSubmit={handleFormSubmit}
          />
        </Col>
        <Col sm={6}>
          <MailgunSubmitForm
            config={config}
            loading={loading}
            statusText={statusText}
            errorObject={errorObject}
            formSubmitted={formSubmitted}
            handleMailgunValueChanged={handleMailgunValueChanged}
            handleFormSubmit={handleFormSubmit}
          />
        </Col>
      </Row>
    </>
  );
};
