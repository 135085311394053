import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
// Components
import CopyToClipboardButton from '@src/components/copy-clipboard';
// Api
import { getApiToken, generateApiToken } from '@src/Api';

export const EndpointPage: React.FC = () => {
  const [endpointToken, setEndpointToken] = useState('');

  useEffect(() => {
    const apiToken = async () => {
      const fetchToken = await getApiToken();
      if (fetchToken && fetchToken.data && 'token' in fetchToken.data) {
        setEndpointToken(fetchToken.data.token);
      }
    };

    apiToken();
  }, []);

  const handleTokenGeneration = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const generatedToken = await generateApiToken();
    if (generatedToken && generatedToken.data && 'token' in generatedToken.data) {
      setEndpointToken(generatedToken.data.token);
    }
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card className="mt-4">
            <Card.Header>Endpoint for availability</Card.Header>
            <Card.Body>
              <Card.Text>
                <b>Welcome to the endpoint area for Rentman and BigCommerce</b>
                <p>Here you can generate a token for the availability endpoint</p>
              </Card.Text>

              <Row>
                <Form onSubmit={handleTokenGeneration}>
                  <Form.Group className="mb-3" controlId="endpoint_url">
                    <Form.Label>URL</Form.Label>
                    <Form.Control type="url" value={`${process.env.REACT_APP_API_URL}/api/availability`} disabled />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="apiKey">
                    <Form.Label>Generate endpoint token</Form.Label>
                    <Form.Control type="text" placeholder="Generate endpoint token" value={endpointToken} readOnly />
                    <CopyToClipboardButton className="mt-2" textToCopy={endpointToken} buttonText="Copy URL" successText="Copied!" />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Generate Token
                  </Button>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
