import React, { useState } from 'react';
import { Row, Col, Card, Button, Spinner, ListGroup } from 'react-bootstrap';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
import SyntaxHighlighter from 'react-syntax-highlighter';
import CopyToClipboardButton from '@src/components/copy-clipboard';

import { importAllProducts } from '@src/Api';

import type { StatusObject, ErrorObject } from './types';

export const ProductSyncPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showStatusObject, setShowStatusObject] = useState(false);
  const [showErrorObject, setShowErrorObject] = useState(false);
  const [statusObject, setStatusObject] = useState<StatusObject>({
    created: 0,
    found: 0,
    skipped: 0,
    failed: 0,
  });
  const [errorObject, setErrorObject] = useState<ErrorObject>({ errors: [] });

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setShowStatusObject(false);
    setShowErrorObject(false);
    setLoading(true);

    const startImport = await importAllProducts();
    if (startImport && startImport.data) {
      if ('error' in startImport.data) {
        setErrorObject(startImport.data);
        setShowErrorObject(true);
      } else {
        const tmpStatusObject = { ...startImport.data };
        delete tmpStatusObject.errors;

        setStatusObject(tmpStatusObject);
        setShowStatusObject(true);

        if (startImport.data.errors && startImport.data.errors.length !== 0) {
          setErrorObject(startImport.data.errors);
          setShowErrorObject(true);
        }
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Product Sync</Card.Header>
            <Card.Body>
              <Button variant="primary" type="submit" disabled={loading} onClick={(e) => handleClick(e)}>
                {loading ? (
                  <>
                    Loading
                    <Spinner animation="border" role="status" size="sm" className="ms-2">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </>
                ) : (
                  'Start Syncing'
                )}
              </Button>

              {showStatusObject && (
                <Row className="mt-4">
                  <b>Sync equipment status</b>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Found Equipment: {statusObject.found}</ListGroup.Item>
                    <ListGroup.Item>Created: {statusObject.created}</ListGroup.Item>
                    <ListGroup.Item>Skipped: {statusObject.skipped}</ListGroup.Item>
                    <ListGroup.Item>Failed: {statusObject.failed}</ListGroup.Item>
                  </ListGroup>
                </Row>
              )}

              {showErrorObject && (
                <>
                  <Row>
                    <div>
                      <SyntaxHighlighter language="json" style={atomOneLight}>
                        {JSON.stringify(errorObject, null, 2)}
                      </SyntaxHighlighter>
                    </div>
                  </Row>
                  <CopyToClipboardButton textToCopy={JSON.stringify(errorObject, null, 2)} buttonText="Copy" successText="Copied!" />
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
