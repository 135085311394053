import React from 'react';
import { Table, Button } from 'react-bootstrap';

import type { ProductTableComponentProps } from './types';

const ProjectTableComponent: React.FC<ProductTableComponentProps> = ({ productLogs, handleShowData }) => {
  return (
    <Table className="enhanced-table" striped responsive>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Log ID</th>
          <th>Request ID</th>
          <th>Source</th>
          <th>Type</th>
          <th>Message</th>
          <th>Status</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        {productLogs.map((productLog, index) => (
          <tr key={index}>
            <td>{new Date(productLog.product_log_timestamp).toLocaleString('da-DK')}</td>
            <td>{productLog.product_log_id}</td>
            <td>{productLog.request_id}</td>
            <td>{productLog.product_log_source}</td>
            <td>{productLog.product_log_type}</td>
            <td>{productLog.product_log_message}</td>
            <td>{productLog.product_log_status}</td>
            <td>
              <Button onClick={() => handleShowData(productLog)}>Show data</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProjectTableComponent;
