import fetcher from '@src/lib/fetcher';
import type { AxiosResponse } from 'axios';
import type { UsersIntergrationType } from './pages/connection/types';
import type { BigcommerceUserSettings, RentmanInternalUserSettings } from './pages/settings/types';
import { RentmanUserSettings } from './pages/settings/rentmanApi/types';
import { MailgunUserSettings } from './pages/settings/mailgun/types';

export const login = async (email: string, password: string): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/login`, 'POST', false, {
    email,
    password,
  });
};

//Users integration
export const integrationByType = async (
  type: 'rentman' | 'rentmanInternal' | 'bigcommerce' | 'mailgun',
  selecting?: 'all' | 'api' | 'data',
): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/filter/${type}${selecting ? `?selecting=${selecting}` : ''}`, 'GET', false);
};

export const allUsersIntegration = async (selecting?: 'all' | 'api' | 'data'): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/all${selecting ? `?selecting=${selecting}` : ''}`, 'GET', false);
};

export const setAllUsersIntegration = async (
  config: UsersIntergrationType,
  type: 'rentman' | 'rentmanInternal' | 'bigcommerce' | 'mailgun',
): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/create/${type}`, 'POST', false, config);
};

export const updateUserIntegration = async (
  type: string,
  data: BigcommerceUserSettings | RentmanInternalUserSettings | RentmanUserSettings | MailgunUserSettings,
): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/users/integrations/update/default/data`, 'PUT', false, {
    type,
    data: { users_integrations_default_data: data },
  });
};

// Sync overview
export const getProductSyncLogs = async (limit?: number, page?: number): Promise<AxiosResponse | null> => {
  const queryString = limit || page ? `/${limit || ''}/${page || ''}` : '';
  return await fetcher(`${process.env.REACT_APP_API_URL}/logs/get-sync-logs${queryString}`, 'GET', false);
};

// Rentman
export const getUserIntegrationByUserIdAndType = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentman/test`, 'GET', false);
};

export const getAllFoldersByUserId = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/folders/get-all-folders`, 'GET', false);
};

export const getAllFoldersWithHierarchyByUserId = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/folders/all-folders-hierarchy`, 'GET', false);
};

// Rentman Internal
export const getRentmanInternalContactFolders = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentmanInternal/folder`, 'GET', false);
};

export const getRentmanInternalProjectTypes = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentmanInternal/project/types`, 'GET', false);
};

export const getRentmanInternalProjectStatuses = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentmanInternal/project/statuses`, 'GET', false);
};

export const getRentmanInternalAccountManagers = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentmanInternal/crew/account-managers`, 'GET', false);
};

export const getRentmanInternalStockLocations = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentmanInternal/stock/location`, 'GET', false);
};

export const getAllMappedEquipment = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/rentmanInternal/equipment/all`, 'GET', false);
};

//Bigcommerce
export const getChannelsByUserId = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/bigcommerce/channels`, 'GET', false);
};

export const getTaxclassesByUserId = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/bigcommerce/taxclasses`, 'GET', false);
};

export const getOrderStatusesByUserId = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/bigcommerce/order/statuses`, 'GET', false);
};

export const getAllCategoriesHierarchyByUserId = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/bigcommerce/categories/all`, 'GET', false);
};

// Logs
export const allProductLogs = async (limit?: number, page?: number): Promise<AxiosResponse | null> => {
  const queryString = limit || page ? `/${limit || ''}/${page || ''}` : '';
  return await fetcher(`${process.env.REACT_APP_API_URL}/logs/all${queryString}`, 'GET', false);
};

export const searchedProductLogs = async (limit?: number, page?: number, searchQuery?: string): Promise<AxiosResponse | null> => {
  const path = limit || page ? `/search/${limit || ''}/${page || ''}` : '/search';

  const queryParams: Record<string, string> = {};

  if (searchQuery) {
    queryParams.search = searchQuery;
  }

  const baseUrl = `${process.env.REACT_APP_API_URL}/logs`;
  const urlObj = new URL(`${baseUrl}${path}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};
// Products
export const resyncProduct = async (rentmanId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/products/resync`, 'POST', false, { rentmanId });
};

export const importAllProducts = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/products/import`, 'POST', false);
};

// Orders
export const allOrders = async (limit?: number, page?: number): Promise<AxiosResponse | null> => {
  const queryString = limit || page ? `/${limit || ''}/${page || ''}` : '';
  return await fetcher(`${process.env.REACT_APP_API_URL}/orders/all${queryString}`, 'GET', false);
};

export const resyncOrder = async (orderId: number): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/orders/resync`, 'POST', false, { orderId });
};

// Order Logs
export const allOrderLogs = async (limit?: number, page?: number): Promise<AxiosResponse | null> => {
  const queryString = limit || page ? `/${limit || ''}/${page || ''}` : '';
  return await fetcher(`${process.env.REACT_APP_API_URL}/order/logs/all${queryString}`, 'GET', false);
};

export const searchOrderLogs = async (limit?: number, page?: number, searchQuery?: string): Promise<AxiosResponse | null> => {
  const path = limit || page ? `/search/${limit || ''}/${page || ''}` : '/search';

  const queryParams: Record<string, string> = {};

  if (searchQuery) {
    queryParams.search = searchQuery;
  }

  const baseUrl = `${process.env.REACT_APP_API_URL}/order/logs`;
  const urlObj = new URL(`${baseUrl}${path}`);

  Object.keys(queryParams).forEach((key) => {
    urlObj.searchParams.append(key, queryParams[key]);
  });

  return await fetcher(urlObj.toString(), 'GET', false);
};

// Test Connection
export const testConnection = async (type: 'rentman' | 'rentmanInternal' | 'bigcommerce'): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/${type}/connection/test`, 'GET', false);
};

// Api token
export const getApiToken = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/api/token`, 'GET', false);
};

export const generateApiToken = async (): Promise<AxiosResponse | null> => {
  return await fetcher(`${process.env.REACT_APP_API_URL}/api/token/generate`, 'POST', false);
};
