import React from 'react';
// Styles
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import SyntaxHighlighter from 'react-syntax-highlighter';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
// Types
import { UsersIntergrationFormProps } from './types';
// Components
import CopyToClipboardButton from '@src/components/copy-clipboard';

const RentmanInternalForm: React.FC<UsersIntergrationFormProps> = ({
  config,
  loading,
  statusText,
  errorObject,
  formSubmitted,
  handleRentmanValueChanged,
  handleFormSubmit,
}) => {
  return (
    <>
      <Card>
        <Card.Header>Rentman webshop connection</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="users_integrations_url">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="url"
                value={
                  config.rentmanInternal.users_integrations_api_data.apiUrl && config.rentmanInternal.users_integrations_api_data.apiUrl.length !== 0
                    ? config.rentmanInternal.users_integrations_api_data.apiUrl
                    : 'https://api.rentman.net'
                }
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="apiKey">
              <Form.Label>Webshop Token</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Rentman Webshop Token"
                value={config.rentmanInternal.users_integrations_api_data.apiKey}
                onChange={handleRentmanValueChanged}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={loading['rentmanInternal'] === true ? true : false}
              onClick={(e) => handleFormSubmit(e, 'rentmanInternal')}
            >
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header>Debug</Card.Header>
        <Card.Body>
          {loading['rentmanInternal'] && (
            <>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          )}
          {formSubmitted && (
            <>
              Connection status:
              <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(statusText['rentmanInternal'], undefined, 4)) }} />
              {errorObject.rentman && (
                <>
                  <div>
                    <SyntaxHighlighter language="json" style={atomOneLight}>
                      {JSON.stringify(errorObject.rentman, null, 2)}
                    </SyntaxHighlighter>
                  </div>
                  <CopyToClipboardButton textToCopy={JSON.stringify(errorObject.rentman, null, 2)} buttonText="Copy" successText="Copied!" />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RentmanInternalForm;
