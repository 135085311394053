import { ProductData } from '@src/pages/log/types';

export function compareData(oldProductData: ProductData | null, newProductData: ProductData | null): string[] {
  const changes: string[] = [];
  if (oldProductData && newProductData) {
    Object.keys(newProductData).forEach((key) => {
      const typedKey = key as keyof ProductData;
      if (newProductData[typedKey] !== oldProductData[typedKey]) {
        changes.push(`${typedKey}:\n   Before: ${oldProductData[typedKey] ?? 'N/A'}\n   After: ${newProductData[typedKey] ?? 'N/A'}`);
      }
    });
  }
  return changes;
}
