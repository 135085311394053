import React from 'react';
import { Row, Col, Card, Form, Spinner, Button } from 'react-bootstrap';
import { RentmanInternalForm } from './rentmanInternal';
import { BigcommerceForm } from './bigcommerce';
import { getAllFoldersByUserId, integrationByType, updateUserIntegration } from '@src/Api';

import rentmanBigcommerceLogo from '@public/img/rentman-bigcommerce-logo.png';

import type { BigcommerceUserSettings, RentmanInternalUserSettings } from './types';
import { Folders, RentmanUserSettings } from './rentmanApi/types';
import { RentmanForm } from './rentmanApi';
import { MailgunForm } from './mailgun';
import { MailgunUserSettings } from './mailgun/types';

export const SettingsPage: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [mailgunUserDefaultData, setMailgunUserDefaultData] = React.useState<MailgunUserSettings>({
    email: '',
    connection_email_enabled: false,
    order_email_enabled: false,
  });
  const [bigcommerceUserDefaultData, setBigcommerceUserDefaultData] = React.useState<BigcommerceUserSettings>({});
  const [rentmanUserDefaultData, setRentmanUserDefaultData] = React.useState<RentmanUserSettings>({});
  const [rentmanInternalUserDefaultData, setRentmanInternalUserDefaultData] = React.useState<RentmanInternalUserSettings>({});
  const [folders, setFolders] = React.useState<Folders[]>([]);

  React.useEffect(() => {
    const getDefaultData = async () => {
      const integrationData = await integrationByType('bigcommerce', 'data');
      if (integrationData) {
        setBigcommerceUserDefaultData(integrationData.data.users_integrations_default_data);
      }
      const rentmanInternalIntegrationData = await integrationByType('rentmanInternal', 'data');
      if (rentmanInternalIntegrationData && rentmanInternalIntegrationData.data.users_integrations_default_data !== null) {
        setRentmanInternalUserDefaultData(rentmanInternalIntegrationData.data.users_integrations_default_data);
      }
      const rentmanIntegrationData = await integrationByType('rentman', 'data');
      if (rentmanIntegrationData && rentmanIntegrationData.data.users_integrations_default_data !== null) {
        setRentmanUserDefaultData(rentmanIntegrationData.data.users_integrations_default_data);

        const mailgunIntegrationData = await integrationByType('mailgun', 'data');
        if (mailgunIntegrationData) {
          setMailgunUserDefaultData(mailgunIntegrationData.data.users_integrations_default_data);
        }
      }

      const folderData = await getAllFoldersByUserId();
      if (folderData) {
        setFolders(folderData.data);
      }
    };
    getDefaultData();
  }, []);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setLoading(true);

    const bigcommerceResponse = await updateUserIntegration('bigcommerce', bigcommerceUserDefaultData);

    if (bigcommerceResponse) {
      setBigcommerceUserDefaultData(bigcommerceResponse.data.users_integrations_default_data);
    }

    const rentmanInternalResponse = await updateUserIntegration('rentmanInternal', rentmanInternalUserDefaultData);
    if (rentmanInternalResponse) {
      setRentmanInternalUserDefaultData(rentmanInternalResponse.data.users_integrations_default_data);
    }

    const rentmanResponse = await updateUserIntegration('rentman', rentmanUserDefaultData);
    if (rentmanResponse) {
      setRentmanUserDefaultData(rentmanResponse.data.users_integrations_default_data);
      const mailgunResponse = await updateUserIntegration('mailgun', mailgunUserDefaultData);

      if (mailgunResponse) {
        setMailgunUserDefaultData(mailgunResponse.data.users_integrations_default_data);
      }

      setLoading(false);
    }
  };

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Settings</Card.Header>
            <Card.Body>
              <Form>
                <Row className="mt-4 mb-4">
                  <Col md={11}>
                    <img src={rentmanBigcommerceLogo} alt="Rentman and E-conomic logo" width="300" loading="lazy" />
                  </Col>
                  <Col md={1}>
                    <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                      {loading ? (
                        <>
                          <Spinner animation="border" size="sm" className="mr-2" /> ...
                        </>
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>Here you can edit your settings for the Rentman - BigCommerce integration.</p>
                  </Col>
                </Row>

                <Row className="mt-4 mb-4">
                  <Col>
                    <h4>Rentman Internal</h4>
                    <p>Set how projects are created in Rentman.</p>
                    <RentmanInternalForm
                      rentmanInternalUserDefaultData={rentmanInternalUserDefaultData}
                      setRentmanInternalUserDefaultData={setRentmanInternalUserDefaultData}
                    />
                  </Col>
                </Row>

                <hr />

                <Row className="mt-4 mb-4">
                  <Col>
                    <h4>Rentman API</h4>
                    <p>Set Rentman API settings.</p>
                    <RentmanForm rentmanUserDefaultData={rentmanUserDefaultData} setRentmanUserDefaultData={setRentmanUserDefaultData} folders={folders} />
                  </Col>
                </Row>

                <hr />

                <Row className="mt-4 mb-4">
                  <Col>
                    <h4>BigCommerce</h4>
                    <p>Set how customers are created and updated in your accounts. Customer data is received with the order transfer.</p>
                    <BigcommerceForm bigcommerceUserDefaultData={bigcommerceUserDefaultData} setBigcommerceUserDefaultData={setBigcommerceUserDefaultData} />
                  </Col>
                </Row>

                <hr />

                <Row className="mt-4 mb-4">
                  <Col>
                    <h4>Mailgun</h4>
                    <p>Set when and where to recieve emails from Mailgun</p>
                    <MailgunForm mailgunUserDefaultData={mailgunUserDefaultData} setMailgunUserDefaultData={setMailgunUserDefaultData} />
                  </Col>
                </Row>

                <hr />

                <Row className="mt-4 mb-4">
                  <Col sm={11}></Col>
                  <Col sm={1}>
                    <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                      {loading ? (
                        <>
                          <Spinner animation="border" size="sm" className="mr-2" /> ...
                        </>
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
