import React from 'react';
import { useUsersContext } from '@src/contexts/users';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';

export const AccountPage: React.FC = () => {
  const { usersState } = useUsersContext();

  const rentmanStatus = usersState.user.rentmanConnected ? 'Connected' : 'Not connected';
  const rentmanInternalStatus = usersState.user.rentmanInternalConnected ? 'Connected' : 'Not connected';
  const bigcommerceStatus = usersState.user.bigcommerceConnected ? 'Connected' : 'Not connected';
  console.log(usersState);

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Account</Card.Header>
            <Card.Body>
              <b>Information</b>

              <ListGroup variant="flush">
                <ListGroup.Item>ID: {usersState.user.id}</ListGroup.Item>
                <ListGroup.Item>Company: {usersState.user.company}</ListGroup.Item>
                <ListGroup.Item>Email: {usersState.user.email}</ListGroup.Item>
                <ListGroup.Item>Password: ******</ListGroup.Item>
                <ListGroup.Item>Role: {usersState.user.role[0] === 'admin' ? 'Admininistrator' : 'Bruger'}</ListGroup.Item>
                <ListGroup.Item>Rentman connection: {rentmanStatus}</ListGroup.Item>
                <ListGroup.Item>Rentman webshop connection: {rentmanInternalStatus}</ListGroup.Item>
                <ListGroup.Item>Bigcommerce connection: {bigcommerceStatus}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
