import React from 'react';
import { Accordion, Modal, Button } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import { compareData } from '@src/components/compare-data/compareData';

import type { ProductModalComponentProps } from './types';

const ProjectModalComponent: React.FC<ProductModalComponentProps> = ({ showModal, handleModal, selectedProductLog }) => {
  let oldProductData = selectedProductLog?.old_product_data;
  let newProductData = selectedProductLog?.new_product_data;
  let changesInLog: string[] = [];

  if (typeof oldProductData === 'string') {
    oldProductData = JSON.parse(oldProductData);
  }
  if (typeof newProductData === 'string') {
    newProductData = JSON.parse(newProductData);
  }
  if (oldProductData && newProductData) {
    const changes = compareData(oldProductData, newProductData);
    changesInLog = changes;
  }

  const productLogContent = selectedProductLog
    ? [
        `Log ID: ${selectedProductLog.product_log_id}`,
        `Message: ${selectedProductLog.product_log_message}`,
        `Source: ${selectedProductLog.product_log_source}`,
        `Timestamp: ${new Date(selectedProductLog.product_log_timestamp).toLocaleString('da-DK')}`,
        `Product SKU: ${selectedProductLog.product_log_product_sku}`,
        selectedProductLog.product_log_source === 'Rentman' && `Product ID: ${selectedProductLog.product_log_product_id} Rentman`,
        selectedProductLog.product_log_source === 'BigCommerce' && `Product ID: ${selectedProductLog.product_log_product_id} BigCommerce`,
        ...(changesInLog.length > 0 ? changesInLog : []),
      ]
        .filter(Boolean)
        .join('\n')
    : '';

  const highlightedResponse = selectedProductLog ? syntaxHighlight(JSON.stringify(selectedProductLog.product_log_response, undefined, 4)) : '';

  return (
    <Modal size="lg" show={showModal} onHide={handleModal} className="modal-data" aria-labelledby="contained-modal-title-vcenter" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={['0']} alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Response</Accordion.Header>
            <Accordion.Body>
              {selectedProductLog && (
                <div>
                  <pre>{productLogContent}</pre>
                  <p>Log Response:</p>
                  <pre>
                    <pre dangerouslySetInnerHTML={{ __html: highlightedResponse }} />
                  </pre>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectModalComponent;
