import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { BigcommerceSettingModuleProps } from '../types';
import { getChannelsByUserId, getTaxclassesByUserId, getOrderStatusesByUserId } from '@src/Api';
import { Channel, TaxClass, OrderStatuses } from './types';

export const BigcommerceForm: React.FC<BigcommerceSettingModuleProps> = ({ bigcommerceUserDefaultData, setBigcommerceUserDefaultData }) => {
  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [taxClasses, setTaxclasses] = React.useState<TaxClass[]>([]);
  const [orderStatuses, setOrderStatuses] = React.useState<OrderStatuses>({});

  const fetchChannels = async () => {
    try {
      const response = await getChannelsByUserId();

      if (response && response.data) {
        const channelData: Channel[] = response.data;
        setChannels(channelData);
      }
    } catch (error) {
      console.log('An error occoured fetching channels.');
    }
  };

  const fetchTaxclasses = async () => {
    try {
      const response = await getTaxclassesByUserId();

      if (response && response.data) {
        const taxClassData: TaxClass[] = response.data;
        setTaxclasses(taxClassData);
      }
    } catch (error) {
      console.log('An error occoured fetching channels.');
    }
  };

  const fetchOrderStatuses = async () => {
    try {
      const response = await getOrderStatusesByUserId();

      if (response && response.data) {
        const orderStatuses: OrderStatuses = response.data;
        setOrderStatuses(orderStatuses);
      }
    } catch (error) {
      console.log('An error occoured fetching order statuses.', error);
    }
  };

  const handleProjectCreateTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBigcommerceUserDefaultData((prevState) => ({
      ...prevState,
      projectCreateType: event.target.value,
    }));
  };

  const handleOrderSendStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBigcommerceUserDefaultData((prevState) => ({
      ...prevState,
      orderSendStatus: event.target.value,
    }));
  };

  const handleTaxclassChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newTaxclassId = parseInt(event.target.value, 10);

    setBigcommerceUserDefaultData((prevState) => ({
      ...prevState,
      selectedTaxClassRental: {
        taxClassId: newTaxclassId,
      },
    }));
  };

  const handleTaxclassSaleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newTaxclassId = parseInt(event.target.value, 10);

    setBigcommerceUserDefaultData((prevState) => ({
      ...prevState,
      selectedTaxClassSale: {
        taxClassId: newTaxclassId,
      },
    }));
  };

  const handleChannelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newChannelId = parseInt(event.target.value, 10);

    setBigcommerceUserDefaultData((prevState) => ({
      ...prevState,
      selectedChannel: {
        channelId: newChannelId,
      },
    }));
  };

  const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataObject = event.target.dataset;
    const key = dataObject.key;

    if (key) {
      setBigcommerceUserDefaultData((prevState) => ({
        ...prevState,
        [key]: event.target.value,
      }));
    }
  };

  React.useEffect(() => {
    fetchChannels();
    fetchTaxclasses();
    fetchOrderStatuses();
  }, []);

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectCreateType">
        <Form.Label column sm={6}>
          Select the method to create a project in Rentman
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanProjectCreateType" label="Select the method to create a project in Rentman">
            <Form.Select aria-label="Floating label select" value={bigcommerceUserDefaultData.projectCreateType} onChange={handleProjectCreateTypeChange}>
              <option hidden value="">
                Open this select menu
              </option>

              <option key={`rentmanProjectCreateType-1`} value="request">
                Rental requests
              </option>

              <option key={`rentmanProjectCreateType-2`} value="internal">
                Internal API
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanInvoiceStatus">
        <Form.Label column sm={6}>
          Specify when to transfer orders to Rentman
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanInvoiceStatus" label="Select when to transfer orders">
            <Form.Select aria-label="Floating label select" value={bigcommerceUserDefaultData.orderSendStatus} onChange={handleOrderSendStatusChange}>
              <option hidden value="">
                Open this select menu
              </option>

              {Object.keys(orderStatuses).length !== 0 &&
                Object.keys(orderStatuses).map((statusKey) => {
                  const item = orderStatuses[statusKey];

                  return (
                    <option key={`bigcommerceOrderStatus-${item.id}`} value={item.custom_label}>
                      {item.custom_label}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSelectedChannel">
        <Form.Label column sm={6}>
          Specify which channel to transfer products to
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectSelectChannel" label="Specify which channel to transfer products to">
            <Form.Select
              aria-label="Floating label select"
              value={bigcommerceUserDefaultData.selectedChannel?.channelId.toString() || ''}
              onChange={handleChannelChange}
            >
              <option hidden value="">
                Open this select menu
              </option>
              {channels.map((channel) => (
                <option key={channel.id} value={channel.id.toString()}>
                  {`${channel.name} (ID: ${channel.id})`}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSelectedTaxclassRental">
        <Form.Label column sm={6}>
          Specify which Tax Class to use for Rental
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectTaxclassRental" label="Specify which Tax Class to use">
            <Form.Select
              aria-label="Floating label select"
              value={bigcommerceUserDefaultData.selectedTaxClassRental?.taxClassId.toString() || ''}
              onChange={handleTaxclassChange}
            >
              <option hidden value="">
                Open this select menu
              </option>
              {taxClasses.map((tc) => (
                <option key={tc.id} value={tc.id.toString()}>
                  {`${tc.name} (ID: ${tc.id})`}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSelectedTaxclassSale">
        <Form.Label column sm={6}>
          Specify which Tax Class to use for Sale
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectTaxclassSale" label="Specify which Tax Class to use">
            <Form.Select
              aria-label="Floating label select"
              value={bigcommerceUserDefaultData.selectedTaxClassSale?.taxClassId.toString() || ''}
              onChange={handleTaxclassSaleChange}
            >
              <option hidden value="">
                Open this select menu
              </option>
              {taxClasses.map((tc) => (
                <option key={tc.id} value={tc.id.toString()}>
                  {`${tc.name} (ID: ${tc.id})`}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalProjectStartDate">
        <Form.Label column sm={6}>
          Specify custom field name for Start date
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputProjectStartDate" label="Custom field name of start date">
            <Form.Control
              type="text"
              value={bigcommerceUserDefaultData.startDateField}
              onChange={handleTextInputChange}
              onBlur={() => {
                handleTextInputChange;
              }}
              data-key={'startDateField'}
            />
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalProjectStartTime">
        <Form.Label column sm={6}>
          Specify custom field name for Start time
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputProjectStartTime" label="Custom field name of start time">
            <Form.Control
              type="text"
              value={bigcommerceUserDefaultData.startTimeField}
              onChange={handleTextInputChange}
              onBlur={() => {
                handleTextInputChange;
              }}
              data-key={'startTimeField'}
            />
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalProjectEndDate">
        <Form.Label column sm={6}>
          Specify custom field name for End date
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputProjectEndDate" label="Custom field name of end date">
            <Form.Control
              type="text"
              value={bigcommerceUserDefaultData.endDateField}
              onChange={handleTextInputChange}
              onBlur={() => {
                handleTextInputChange;
              }}
              data-key={'endDateField'}
            />
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalProjectEndTime">
        <Form.Label column sm={6}>
          Specify custom field name for End time
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputProjectEndTime" label="Custom field name of end time">
            <Form.Control
              type="text"
              value={bigcommerceUserDefaultData.endTimeField}
              onChange={handleTextInputChange}
              onBlur={() => {
                handleTextInputChange;
              }}
              data-key={'endTimeField'}
            />
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
