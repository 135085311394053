import React from 'react';

import { UsersContextProvider } from '@src/contexts/users';
import { MainPage } from '@src/pages/main';

import type { Children } from '@src/types';

const AppContextProvider: React.FC<Children> = ({ children }) => {
  return <UsersContextProvider>{children}</UsersContextProvider>;
};

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <MainPage />
    </AppContextProvider>
  );
};

export default App;
