import { getAllMappedEquipment } from '@src/Api';
import React, { useState } from 'react';
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import { RentmanEquipmentFiles } from './types';

export const ExportPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const exportEquipment = async () => {
    try {
      setLoading(true);
      const equipmentResponse = await getAllMappedEquipment();

      if (equipmentResponse && 'error' in equipmentResponse) {
        setErrorMessage('An error occurred getting all equipment.');
        return;
      }

      if (equipmentResponse?.data) {
        const csvData = convertToCSV(equipmentResponse.data);
        const currentDate = new Date().toISOString().split('T')[0];
        downloadCSV(csvData, `RentmanEquipment_${currentDate}.csv`);
      }
    } catch (error) {
      console.error('Error exporting equipment:', error);
      setErrorMessage('Error exporting equipment.');
    } finally {
      setLoading(false);
    }
  };

  const convertToCSV = (data: RentmanEquipmentFiles[]) => {
    const customFieldsSet = new Set<string>();
    data.forEach((item) => {
      if (item.custom) {
        const custom = typeof item.custom === 'string' ? JSON.parse(item.custom) : item.custom;
        Object.keys(custom).forEach((key) => customFieldsSet.add(key));
      }
    });

    const customFields = Array.from(customFieldsSet);

    const headers = [
      'id',
      'created',
      'modified',
      'creator',
      'displayname',
      'folder',
      'code',
      'name',
      'internal_remark',
      'external_remark',
      'unit',
      'in_shop',
      'surface_article',
      'shop_description_short',
      'shop_description_long',
      'shop_seo_title',
      'shop_seo_keyword',
      'shop_seo_description',
      'shop_featured',
      'price',
      'subrental_costs',
      'critical_stock_level',
      'type',
      'rental_sales',
      'temporary',
      'in_planner',
      'in_archive',
      'stock_management',
      'taxclass',
      'list_price',
      'volume',
      'packed_per',
      'height',
      'width',
      'length',
      'weight',
      'power',
      'current',
      'country_of_origin',
      'image',
      'ledger',
      'defaultgroup',
      'is_combination',
      'is_physical',
      'can_edit_content_during_planning',
      'qrcodes',
      'qrcodes_of_serial_numbers',
      'tags',
      'current_quantity_excl_cases',
      'current_quantity',
      'quantity_in_cases',
      'location_in_warehouse',
      ...customFields,
      'file_1',
      'file_2',
      'file_3',
      'file_4',
      'file_5',
      'file_6',
      'file_7',
      'file_8',
      'file_9',
      'file_10',
    ];

    const csvRows = [];

    csvRows.push(headers.join(';'));

    data.forEach((item) => {
      const row = headers.map((header) => {
        let value: unknown = '';
        if (customFields.includes(header)) {
          const custom = typeof item.custom === 'string' ? JSON.parse(item.custom) : item.custom;
          value = custom[header] ?? '';
        } else if (header.startsWith('file_')) {
          value = item[header as keyof RentmanEquipmentFiles] ?? '';
        } else {
          value = item[header as keyof RentmanEquipmentFiles] ?? '';
        }
        if (typeof value === 'object' && value !== null) {
          value = JSON.stringify(value);
        }
        const escaped = ('' + value).replace(/"/g, '""').replace(/\n/g, ' ').replace(/\r/g, ' ');
        return `"${escaped}"`;
      });
      csvRows.push(row.join(';'));
    });

    return csvRows.join('\n');
  };

  const downloadCSV = (csvData: string, filename: string) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Export</Card.Header>
            <Card.Body>
              <Button disabled={loading} onClick={exportEquipment}>
                {loading ? (
                  <>
                    Preparing file for download...
                    <Spinner animation="border" role="status" size="sm" className="ms-2" />
                  </>
                ) : (
                  'Export equipment'
                )}
              </Button>
              {errorMessage && <p className="error text-center">{errorMessage}</p>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
