import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';
import {
  getRentmanInternalContactFolders,
  getRentmanInternalProjectTypes,
  getRentmanInternalProjectStatuses,
  getRentmanInternalAccountManagers,
  getRentmanInternalStockLocations,
} from '@src/Api';

import type { RentmanInternalSettingModuleProps } from '../types';
import type { Folders, IdAndDisplayname } from './types';

export const RentmanInternalForm: React.FC<RentmanInternalSettingModuleProps> = ({ rentmanInternalUserDefaultData, setRentmanInternalUserDefaultData }) => {
  const [folders, setFolders] = React.useState<Folders>({});
  const [projectTypes, setProjectTypes] = React.useState<IdAndDisplayname>({});
  const [projectStatuses, setProjectStatuses] = React.useState<IdAndDisplayname>({});
  const [accountManagers, setAccountManagers] = React.useState<IdAndDisplayname>({});
  const [stockLocations, setStockLocations] = React.useState<IdAndDisplayname>({});

  React.useEffect(() => {
    const getContactFolders = async () => {
      const getFolders = await getRentmanInternalContactFolders();
      if (getFolders) {
        setFolders(getFolders.data);
      }
    };

    const getProjectType = async () => {
      const getTypes = await getRentmanInternalProjectTypes();
      if (getTypes) {
        setProjectTypes(getTypes.data);
      }
    };

    const getProjectStatus = async () => {
      const getStatuses = await getRentmanInternalProjectStatuses();
      if (getStatuses) {
        setProjectStatuses(getStatuses.data);
      }
    };

    const getAccountManagers = async () => {
      const getManagers = await getRentmanInternalAccountManagers();
      if (getManagers) {
        setAccountManagers(getManagers.data);
      }
    };

    const getStockLocations = async () => {
      const getLocations = await getRentmanInternalStockLocations();
      if (getLocations) {
        setStockLocations(getLocations.data);
      }
    };

    getContactFolders();
    getProjectType();
    getProjectStatus();
    getAccountManagers();
    getStockLocations();
  }, []);

  const handleSelectboxChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const dataObject = event.target.dataset;
    const key = dataObject.key;

    if (key) {
      setRentmanInternalUserDefaultData((prevState) => ({
        ...prevState,
        [key]: event.target.value,
      }));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataObject = event.target.dataset;
    const key = dataObject.key;

    if (key) {
      setRentmanInternalUserDefaultData((prevState) => ({
        ...prevState,
        [key]: key === 'projectColor' ? event.target.value.replace('#', '') : event.target.value,
      }));
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectClientFolder">
        <Form.Label column sm={6}>
          Select clients folder
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanProjectClientFolder" label="Select clients folder">
            <Form.Select
              aria-label="Floating label select"
              value={rentmanInternalUserDefaultData.projectClientFolder}
              onChange={handleSelectboxChange}
              data-key="projectClientFolder"
            >
              <option hidden value="">
                Open this select menu
              </option>

              {Object.keys(folders).length !== 0 &&
                Object.keys(folders).map((key) => {
                  return (
                    <option key={folders[key].id} value={folders[key].id}>
                      {`${folders[key].naam}`}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectLocationFolder">
        <Form.Label column sm={6}>
          Select location folder
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanProjectLocationFolder" label="Select location folder">
            <Form.Select
              aria-label="Floating label select"
              value={rentmanInternalUserDefaultData.projectLocationFolder}
              onChange={handleSelectboxChange}
              data-key="projectLocationFolder"
            >
              <option hidden value="">
                Open this select menu
              </option>

              {Object.keys(folders).length !== 0 &&
                Object.keys(folders).map((key) => {
                  return (
                    <option key={folders[key].id} value={folders[key].id}>
                      {`${folders[key].naam}`}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectDefaultStockLocationFolder">
        <Form.Label column sm={6}>
          Select default stock location
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanProjectLocationFolder" label="Select default stock location">
            <Form.Select
              aria-label="Floating label select"
              value={rentmanInternalUserDefaultData.defaultStockLocation}
              onChange={handleSelectboxChange}
              data-key="defaultStockLocation"
            >
              <option hidden value="">
                Open this select menu
              </option>

              {Object.keys(stockLocations).length !== 0 &&
                Object.keys(stockLocations).map((key) => {
                  return (
                    <option key={stockLocations[key].id} value={stockLocations[key].id}>
                      {`${stockLocations[key].displayname}`}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectType">
        <Form.Label column sm={6}>
          Select project type
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanProjectType" label="Select project type">
            <Form.Select
              aria-label="Floating label select"
              value={rentmanInternalUserDefaultData.projectType}
              onChange={handleSelectboxChange}
              data-key="projectType"
            >
              <option hidden value="">
                Open this select menu
              </option>

              {Object.keys(projectTypes).length !== 0 &&
                Object.keys(projectTypes).map((key) => {
                  return (
                    <option key={projectTypes[key].id} value={projectTypes[key].id}>
                      {`${projectTypes[key].displayname}`}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectStatus">
        <Form.Label column sm={6}>
          Select project status
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanProjectStatus" label="Select project status">
            <Form.Select
              aria-label="Floating label select"
              value={rentmanInternalUserDefaultData.projectStatus}
              onChange={handleSelectboxChange}
              data-key="projectStatus"
            >
              <option hidden value="">
                Open this select menu
              </option>

              {Object.keys(projectStatuses).length !== 0 &&
                Object.keys(projectStatuses).map((key) => {
                  return (
                    <option key={projectStatuses[key].id} value={projectStatuses[key].id}>
                      {`${projectStatuses[key].displayname}`}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectName">
        <Form.Label column sm={6}>
          Specify project name
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRentmanProjectName" label="Custom project name">
            <Form.Control
              type="text"
              value={rentmanInternalUserDefaultData.projectName}
              onChange={handleInputChange}
              onBlur={() => {
                handleInputChange;
              }}
              data-key="projectName"
            />
            <Form.Text muted>
              You can use the following variables: [BillingCompanyOrName], [BillingCompany], [BillingFirstName], [BillingLastName], [OrderNumber],
              [ShippingCompanyOrName], [ShippingCompany], [ShippingFirstName], [ShippingLastName]
            </Form.Text>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectColor">
        <Form.Label column sm={6}>
          Specify Project color
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRentmanProjectName" label="Custom project color">
            <Form.Control
              type="color"
              value={`#${rentmanInternalUserDefaultData.projectColor}`}
              onChange={handleInputChange}
              onBlur={() => {
                handleInputChange;
              }}
              data-key="projectColor"
            />
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectAccountManager">
        <Form.Label column sm={6}>
          Select project account manager
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingSelectRentmanProjectAccountManager" label="Select project account manager">
            <Form.Select
              aria-label="Floating label select"
              value={rentmanInternalUserDefaultData.projectAccountManager}
              onChange={handleSelectboxChange}
              data-key="projectAccountManager"
            >
              <option hidden value="">
                Open this select menu
              </option>

              {Object.keys(accountManagers).length !== 0 &&
                Object.keys(accountManagers).map((key) => {
                  return (
                    <option key={accountManagers[key].id} value={accountManagers[key].id}>
                      {`${accountManagers[key].displayname}`}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectUsageStartTime">
        <Form.Label column sm={6}>
          Specify Project usage period start time
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRentmanProjectUsageStartTime" label="Custom project usage period start time">
            <Form.Control
              type="time"
              value={rentmanInternalUserDefaultData.projectUsagePeriodStartTime}
              onChange={handleInputChange}
              onBlur={() => {
                handleInputChange;
              }}
              data-key="projectUsagePeriodStartTime"
              step="60"
            />
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanProjectUsageEndTime">
        <Form.Label column sm={6}>
          Specify Project usage period end time
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputRentmanProjectUsageEndTime" label="Custom project usage period end time">
            <Form.Control
              type="time"
              value={rentmanInternalUserDefaultData.projectUsagePeriodEndTime}
              onChange={handleInputChange}
              onBlur={() => {
                handleInputChange;
              }}
              data-key="projectUsagePeriodEndTime"
              step="60"
            />
          </FloatingLabel>
        </Col>
      </Form.Group>
    </>
  );
};
