import React from 'react';
import { RentmanSettingModuleProps } from '../types';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

export const RentmanForm: React.FC<RentmanSettingModuleProps> = ({ rentmanUserDefaultData, setRentmanUserDefaultData, folders }) => {
  const handleCustomImageFields = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const customField = event.target.value;

    setRentmanUserDefaultData((prevState) => ({
      ...prevState,
      [key]: customField,
    }));
  };

  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      [key]: event.target.checked,
    });
  };

  const handleCheckboxChange = (folderId: number) => {
    setRentmanUserDefaultData((prevState) => {
      const isIdIncluded = prevState.includedIds?.includes(folderId);

      const updatedIncludedIds = isIdIncluded ? prevState.includedIds?.filter((id) => id !== folderId) : [...(prevState.includedIds || []), folderId];

      return { ...prevState, includedIds: updatedIncludedIds };
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={6}>
          Enable choosing which Rentman folder to include when updating equipment.
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Enable"
            checked={rentmanUserDefaultData.enable_folder_includes ? rentmanUserDefaultData.enable_folder_includes : false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormCheckChange(event, 'enable_folder_includes')}
          />
        </Col>
      </Form.Group>
      {rentmanUserDefaultData.enable_folder_includes && rentmanUserDefaultData.enable_folder_includes === true && (
        <>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={6}>
              Select which Rentman folders to include when updating equipment.
            </Form.Label>
            <Col sm={6}>
              <div className="scrollable-checkbox-list">
                {folders.map(
                  (folder) =>
                    folder.itemtype === 'equipment' && (
                      <Form.Check
                        key={folder.id}
                        type="checkbox"
                        id={`folder-${folder.id}`}
                        label={`${folder.name} (ID: ${folder.id})`}
                        checked={rentmanUserDefaultData.includedIds?.includes(folder.id) || false}
                        onChange={() => handleCheckboxChange(folder.id)}
                      />
                    ),
                )}
              </div>
            </Col>
          </Form.Group>
        </>
      )}
      <Form.Group
        key={`rentmanCustomInstallationInstructionsField`}
        as={Row}
        className="mb-3"
        controlId={`formHorizontalRentmanCustomInstallationInstructionsField`}
      >
        <Form.Label column sm={6}>
          Specify custom field for View Installation instructions
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId={`floatingInputInstallationInstructions`} label="Specify which Custom Field Name to use">
            <Form.Control
              aria-label="Floating label select"
              value={(rentmanUserDefaultData.installationInstructions as string) || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `installationInstructions`)}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group key={`rentmanCustomExtraBadgeField`} as={Row} className="mb-3" controlId={`formHorizontalRentmanCustomExtraBadgeField`}>
        <Form.Label column sm={6}>
          Specify custom field for Extra badge
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId={`floatingInputExtraBadgeUrl`} label="Specify which Custom Field Name to use">
            <Form.Control
              aria-label="Floating label select"
              value={(rentmanUserDefaultData.extraBadge as string) || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `extraBadge`)}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group key={`rentmanCustomMinimumChargeField`} as={Row} className="mb-3" controlId={`formHorizontalRentmanCustomMinimumChargeField`}>
        <Form.Label column sm={6}>
          Specify custom field for Minimum charge
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId={`floatingInputMinimumChargeUrl`} label="Specify which Custom Field Name to use">
            <Form.Control
              aria-label="Floating label select"
              value={(rentmanUserDefaultData.minimumCharge as string) || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `minimumCharge`)}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group key={`rentmanCustomSkuParentField`} as={Row} className="mb-3" controlId={`formHorizontalRentmanCustomSkuParentField`}>
        <Form.Label column sm={6}>
          Specify custom field for SKU parent
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId={`floatingInputSkuParentUrl`} label="Specify which Custom Field Name to use">
            <Form.Control
              aria-label="Floating label select"
              value={(rentmanUserDefaultData.skuParent as string) || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `skuParent`)}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group key={`rentmanCustomProductionSheetField`} as={Row} className="mb-3" controlId={`formHorizontalRentmanCustomProductionSheetField`}>
        <Form.Label column sm={6}>
          Specify custom field for Production sheet
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId={`floatingInputProductionSheet`} label="Specify which Custom Field Name to use">
            <Form.Control
              aria-label="Floating label select"
              value={(rentmanUserDefaultData.productionSheet as string) || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `productionSheet`)}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group key={`rentmanCustomProductDimensionsField`} as={Row} className="mb-3" controlId={`formHorizontalRentmanCustomProductDimensionsField`}>
        <Form.Label column sm={6}>
          Specify custom field for Product dimensions
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId={`floatingInputProductDimensions`} label="Specify which Custom Field Name to use">
            <Form.Control
              aria-label="Floating label select"
              value={(rentmanUserDefaultData.productDimensions as string) || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `productDimensions`)}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group key={`rentmanCustomGenericEquipmentNameField`} as={Row} className="mb-3" controlId={`formHorizontalRentmanCustomGenericEquipmentNameField`}>
        <Form.Label column sm={6}>
          Specify custom field for Generic equipment name
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId={`floatingInputGenericEquipmentName`} label="Specify which Custom Field Name to use">
            <Form.Control
              aria-label="Floating label select"
              value={(rentmanUserDefaultData.genericEquipmentName as string) || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `genericEquipmentName`)}
            ></Form.Control>
          </FloatingLabel>
        </Col>
      </Form.Group>
      {Array.from([1, 2, 3, 4, 5]).map((index) => {
        return (
          <Form.Group key={`rentmanCustomImageField-${index}`} as={Row} className="mb-3" controlId={`formHorizontalRentmanCustomImageField-${index}`}>
            <Form.Label column sm={6}>
              Specify custom field for Image Url {index !== 1 ? index : ''}
            </Form.Label>
            <Col sm={6}>
              <FloatingLabel controlId={`floatingInputImageUrl-${index !== 1 ? index : ''}`} label="Specify which Custom Field Name to use">
                <Form.Control
                  aria-label="Floating label select"
                  value={(rentmanUserDefaultData[`customImageUrlField${index !== 1 ? index : ''}`] as string) || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCustomImageFields(event, `customImageUrlField${index !== 1 ? index : ''}`)}
                ></Form.Control>
              </FloatingLabel>
            </Col>
          </Form.Group>
        );
      })}
    </>
  );
};
