import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { useUsersContext } from '@src/contexts/users';

import { CustomButton } from '@src/components/custom-button';

// Images
import setupGuideImage from '@public/img/setup-guide.svg';
import settingsImage from '@public/img/settings.svg';
import connectionsImage from '@public/img/connections.svg';
import rentmanBigcommerce from '@public/img/rentman-bigcommerce-logo.png';

export const DashboardPage: React.FC = () => {
  const { usersState } = useUsersContext();

  const isApiConnected = (): boolean => {
    if (usersState.user.rentmanConnected) {
      return usersState.user.rentmanConnected;
    }

    return false;
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card className="mt-4">
            <Card.Header>Dashboard</Card.Header>
            <Card.Body className="dashboard-body">
              <Row className="dashboard-body">
                <Card.Title className="dashboard-title">Hi {usersState.user.company}! </Card.Title>
                <Card.Text>
                  <b>Welcome to the integration for Rentman and BigCommerce</b>
                  <p>Here you can view general information regarding the integration.</p>
                </Card.Text>
                <Row className="image-row">
                  <img className="dashboard-image" src={`${rentmanBigcommerce}`} alt="Rentman and BigCommerce logo"></img>
                </Row>
                <Col className="col-6"></Col>
              </Row>

              <Container className="buttons-container">
                <Row>
                  <p className="section-header">Get Started!</p>
                </Row>
                <Row>
                  <Col className="dashboard-custom-button-container">
                    <CustomButton description={'Start setup here!'} title={'Setup Guide'} image={setupGuideImage} href="/setup-guide" />
                  </Col>
                  <Col className="dashboard-custom-button-container">
                    <CustomButton description={'Go to connections here!'} title={'Connections'} image={connectionsImage} href="/connection-config" />
                  </Col>
                  {isApiConnected() === true && (
                    <Col className="dashboard-custom-button-container">
                      <CustomButton description={'Go to settings here!'} title={'Settings'} image={settingsImage} href="/settings" />
                    </Col>
                  )}
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
