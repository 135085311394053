import React from 'react';
import { Accordion, Modal, Button } from 'react-bootstrap';
import { syntaxHighlight } from '../../../components/syntax-highlight';

import type { OrderModalComponentProps } from './types';

const OrderModalComponent: React.FC<OrderModalComponentProps> = ({ showModal, handleModal, selectedOrderLog }) => {
  const logContent = selectedOrderLog
    ? `
Log ID: ${selectedOrderLog.order_log_id}
Order ID: ${selectedOrderLog.order_id}
Type: ${selectedOrderLog.order_log_source}
Event: ${selectedOrderLog.order_log_message}
Status: ${selectedOrderLog.order_log_status}
Timestamp: ${new Date(selectedOrderLog.order_log_created).toLocaleString('da-DK')}
    `
    : '';

  const highlightedResponse = selectedOrderLog ? syntaxHighlight(JSON.stringify(selectedOrderLog.order_log_body, undefined, 4)) : '';

  return (
    <Modal size="lg" show={showModal} onHide={handleModal} className="modal-data" aria-labelledby="contained-modal-title-vcenter" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={['0']} alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Response</Accordion.Header>
            <Accordion.Body>
              {selectedOrderLog && (
                <div>
                  <pre>{logContent}</pre>
                  <p>Log response:</p>
                  <pre dangerouslySetInnerHTML={{ __html: highlightedResponse }} />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderModalComponent;
