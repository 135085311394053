import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { DashboardPage } from '@src/pages/dashboard';
import { UserConfigPage } from '@src/pages/connection';
import { EndpointPage } from '@src/pages/endpoint';
import { useUsersContext } from '@src/contexts/users';
import { LoginPage } from '@src/pages/login';
import { AccountPage } from '@src/pages/account';
import { SettingsPage } from '../settings';
import { SyncOverviewPage } from '../sync-overview';
import { LogPage } from '../log';
import { ProductSyncPage } from '../productSync';
import { CategoryMappingPage } from '../categoryMapping';
import { Col, Container, Row, Nav, Offcanvas, NavDropdown, Navbar, Dropdown } from 'react-bootstrap';

import { UsersActions } from '@src/contexts/users/types';

import companyLogo from '@public/img/logo_green.png';
import { ExportPage } from '../export';

export const MainPage: React.FC = () => {
  const { usersState, usersDispatch } = useUsersContext();

  const [show, setShow] = React.useState(false);

  const toggleOffCanvas = (): void => {
    setShow((show) => !show);
  };

  const SignOut = (): void => {
    usersDispatch({ type: UsersActions.LOGOUT, payload: '' });
  };

  const isApiConnected = (): boolean => {
    if (usersState.user.bigcommerceConnected === true && usersState.user.rentmanConnected === true && usersState.user.rentmanInternalConnected === true) {
      return true;
    }

    return false;
  };

  if (usersState.token.length === 0) {
    return <LoginPage />;
  }

  return (
    <BrowserRouter>
      <Container fluid className="main-wrapper">
        <Row className="header">
          <Col xs={6}>
            <a href="/">
              <img src={companyLogo} className="" alt="IT Stack logo med animation" width="120" loading="lazy" />
            </a>
          </Col>
          <Col xs={6} className="d-flex justify-content-end my-auto">
            <a href="/#" onClick={SignOut} className="sign-out">
              <b>Sign out</b>
            </a>
          </Col>
        </Row>

        <Row className="main-content">
          <Col sm={12} lg={2} className="sidebar-wrapper">
            <Row className="d-none d-sm-none d-md-none d-lg-block">
              <Col sm={12} className="user-wrapper">
                <p>
                  <b>{usersState.user.company}</b>
                </p>
                <p>ID: {usersState.user.id}</p>
              </Col>

              <hr />

              <Col sm={12} className="menu-wrapper">
                <Navbar>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto flex-column">
                      <LinkContainer to="/">
                        <Nav.Link>Dashboard</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/sync-overview">
                        <Nav.Link>Sync overview</Nav.Link>
                      </LinkContainer>
                      <Dropdown className="navbar-dropdown" autoClose={false} navbar={true}>
                        <Dropdown.Toggle id="dropdown-autoclose-false">Integration</Dropdown.Toggle>

                        <Dropdown.Menu>
                          <LinkContainer to="/account">
                            <Dropdown.Item>Account</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/connection-config">
                            <Dropdown.Item>Connections</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/endpoint">
                            <Dropdown.Item>Endpoint</Dropdown.Item>
                          </LinkContainer>
                          {isApiConnected() === true && (
                            <>
                              <LinkContainer to="/settings">
                                <Dropdown.Item>Settings</Dropdown.Item>
                              </LinkContainer>
                              <LinkContainer to="/export">
                                <Dropdown.Item>Export</Dropdown.Item>
                              </LinkContainer>
                              <LinkContainer to="/category-mapping">
                                <Nav.Link>Category Mapping</Nav.Link>
                              </LinkContainer>
                              <LinkContainer to="/product-sync">
                                <Dropdown.Item>Product Sync</Dropdown.Item>
                              </LinkContainer>
                            </>
                          )}
                          <LinkContainer to="/log">
                            <Dropdown.Item>Log</Dropdown.Item>
                          </LinkContainer>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>

            <Row className="d-block d-sm-block d-md-block d-lg-none">
              <Navbar collapseOnSelect expand={false}>
                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggleOffCanvas} />
                <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="start" show={show} onHide={toggleOffCanvas}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Col sm={12} className="user-wrapper">
                      <p>
                        <b>{usersState.user.company}</b>
                      </p>
                      <p>ID: {usersState.user.id}</p>
                    </Col>

                    <hr />

                    <Nav className="justify-content-end flex-grow-1 pe-3 offcanvas--menu mobile-menu">
                      <LinkContainer to="/" onClick={toggleOffCanvas}>
                        <Nav.Link>Dashboard</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/sync-overview" onClick={toggleOffCanvas}>
                        <Nav.Link>Sync overview</Nav.Link>
                      </LinkContainer>
                      <NavDropdown title="Settings" id="basic-nav-dropdown">
                        <LinkContainer to="/account" onClick={toggleOffCanvas}>
                          <Nav.Link>Account</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/connection-config" onClick={toggleOffCanvas}>
                          <Nav.Link>Connections</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/endpoint" onClick={toggleOffCanvas}>
                          <Nav.Link>Endpoint</Nav.Link>
                        </LinkContainer>
                        {isApiConnected() === true && (
                          <>
                            <LinkContainer to="/settings" onClick={toggleOffCanvas}>
                              <Nav.Link>Settings</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/export" onClick={toggleOffCanvas}>
                              <Dropdown.Item>Export</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/category-mapping" onClick={toggleOffCanvas}>
                              <Nav.Link>Category Mapping</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/product-sync" onClick={toggleOffCanvas}>
                              <Nav.Link>Product Sync</Nav.Link>
                            </LinkContainer>
                          </>
                        )}
                        <LinkContainer to="/log" onClick={toggleOffCanvas}>
                          <Nav.Link>Log</Nav.Link>
                        </LinkContainer>
                      </NavDropdown>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            </Row>
          </Col>

          <Col sm={12} lg={10} className="content-wrapper">
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/sync-overview" element={<SyncOverviewPage />} />
              <Route path="/connection-config" element={<UserConfigPage />} />
              <Route path="/endpoint" element={<EndpointPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/export" element={<ExportPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/product-sync" element={<ProductSyncPage />} />
              <Route path="/category-mapping" element={<CategoryMappingPage />} />
              <Route path="/log" element={<LogPage />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  );
};
