import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
// Types
import { ProductLogsData, OrderLogsData } from './types';
// Project Components
import ProjectModalComponent from './modals/productModal';
import ProjectTableComponent from './tables/productTable';
// Order Components
import OrderModalComponent from './modals/orderModal';
import OrderTableComponent from './tables/orderTable';

import { PaginationComponent } from '../../components/pagination';
// API
import { allProductLogs, searchedProductLogs, allOrderLogs, searchOrderLogs } from '@src/Api';

export const LogPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(10);
  const [resultsPerPage] = useState<number>(10);
  const [selectedLog, setSelectedLog] = useState<ProductLogsData | OrderLogsData | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>(localStorage.getItem('activeLog') || 'Product');
  const [activeLogsData, setActiveLogsData] = useState<ProductLogsData[] | OrderLogsData[]>([]);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleShowData = (log: ProductLogsData | OrderLogsData) => {
    setSelectedLog(log);
    setShowModal(true);
  };

  const handleClearSearch = async () => {
    setSearchQuery('');
    setSelectedOption('Product');
    await fetchLogs();
  };

  const fetchLogs = async (): Promise<void> => {
    let response;
    if (selectedOption === 'Product') {
      if (searchQuery.length !== 0) {
        response = await searchedProductLogs(resultsPerPage, currentPage, searchQuery);
      } else {
        response = await allProductLogs(resultsPerPage, currentPage);
      }
    }

    if (selectedOption === 'Order') {
      if (searchQuery.length !== 0) {
        response = await searchOrderLogs(resultsPerPage, currentPage, searchQuery);
      } else {
        response = await allOrderLogs(resultsPerPage, currentPage);
      }
    }

    if (response) {
      setActiveLogsData(response.data.results);
      setTotalResults(response.data.total);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    fetchLogs();
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    setSelectedOption(selected);
    localStorage.setItem('activeLog', selected);
  };

  useEffect(() => {
    fetchLogs();
  }, [currentPage, resultsPerPage, searchQuery, selectedOption]);

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Log</Card.Header>
            <Card.Body>
              <Row className="mt-4">
                <Col>
                  <p>Log overview</p>
                </Col>
                <Col>
                  <select className="form-select" aria-label="Default select" value={selectedOption} onChange={handleSelectChange}>
                    <option value="Product">Product</option>
                    <option value="Order">Order</option>
                  </select>
                </Col>
                <Col>
                  <input
                    type="search"
                    placeholder="Search logs"
                    id="form1"
                    className="form-control"
                    value={searchQuery}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch(searchQuery);
                      }
                    }}
                  />
                </Col>
                <Col className="search-btn-col">
                  <Button onClick={() => handleSearch(searchQuery)}>Search</Button>
                  <Button variant="secondary" onClick={() => handleClearSearch()}>
                    Clear
                  </Button>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {selectedOption === 'Product' && <ProjectTableComponent handleShowData={handleShowData} productLogs={activeLogsData as ProductLogsData[]} />}
                  {selectedOption === 'Order' && <OrderTableComponent handleShowData={handleShowData} orderLogs={activeLogsData as OrderLogsData[]} />}
                </Col>
              </Row>

              {selectedOption === 'Product' && (
                <ProjectModalComponent showModal={showModal} handleModal={handleModal} selectedProductLog={selectedLog as ProductLogsData} />
              )}
              {selectedOption === 'Order' && (
                <OrderModalComponent showModal={showModal} handleModal={handleModal} selectedOrderLog={selectedLog as OrderLogsData} />
              )}

              <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                  <PaginationComponent
                    itemsCount={totalResults}
                    itemsPerPage={resultsPerPage}
                    currentPage={currentPage + 1}
                    setCurrentPage={(pageChangeTo) => setCurrentPage(pageChangeTo - 1)}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
