import React from 'react';
import { MailgunProps } from './types';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { syntaxHighlight } from '@src/components/syntax-highlight';
import atomOneLight from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light';
import SyntaxHighlighter from 'react-syntax-highlighter';
import CopyToClipboardButton from '@src/components/copy-clipboard';

export const MailgunSubmitForm: React.FC<MailgunProps> = ({
  config,
  loading,
  statusText,
  errorObject,
  formSubmitted,
  handleMailgunValueChanged,
  handleFormSubmit,
}) => {
  return (
    <>
      <Card>
        <Card.Header>Mailgun Connection</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="apiKey">
              <Form.Label>API key</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mailgun API token"
                value={config.mailgun.users_integrations_api_data.apiKey}
                onChange={handleMailgunValueChanged}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="domain">
              <Form.Label>Domain</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Mailgun domain"
                value={config.mailgun.users_integrations_api_data.domain}
                onChange={handleMailgunValueChanged}
              ></Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" onClick={(e) => handleFormSubmit(e, 'mailgun')}>
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header>Debug</Card.Header>
        <Card.Body>
          {loading['mailgun'] && (
            <>
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          )}
          {formSubmitted && (
            <>
              Connection status:
              <pre dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(statusText['mailgun'], undefined, 4)) }} />
              {errorObject.rentman && (
                <>
                  <div>
                    <SyntaxHighlighter language="json" style={atomOneLight}>
                      {JSON.stringify(errorObject.rentman, null, 2)}
                    </SyntaxHighlighter>
                  </div>
                  <CopyToClipboardButton textToCopy={JSON.stringify(errorObject.rentman, null, 2)} buttonText="Copy" successText="Copied!" />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
