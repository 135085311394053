import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

if (process.env.ENVIRONMENT !== 'development' && process.env.REACT_APP_API_URL !== undefined) {
  Sentry.init({
    environment: process.env.ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: function (integrations) {
      return [
        ...integrations,
        new BrowserTracing({
          tracingOrigins: [`${process.env.REACT_APP_API_URL}`],
        }),
      ];
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    sampleRate: 1.0,
  });
}

const container = document.getElementById('root') as Element | DocumentFragment;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
