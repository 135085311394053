import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Tab, Tabs, Container } from 'react-bootstrap';
import { AnimatePresence } from 'framer-motion';

// Components
import { PaginationComponent } from '@src/components/pagination';
import { CustomToast } from '@src/components/toast';
import { ProductData, OrderData } from './types';
import { getProductSyncLogs, allOrders } from '@src/Api';
import { ProductsTab } from './productsTab';
import { OrdersTab } from './ordersTab';

export const SyncOverviewPage: React.FC = () => {
  //useState hooks
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [productSync, setProductSync] = useState<ProductData[]>([]);
  const [orderSync, setOrderSync] = useState<OrderData[]>([]);
  const [totalResults, setTotalResults] = useState<number>(10);
  const [resultsPerPage] = useState<number>(10);
  const [activeKey, setActiveKey] = useState(localStorage.getItem('activeTab') || 'products');
  const [toasts, setToasts] = useState<{ id: number; message: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectTab = (key: string | null) => {
    if (key) {
      localStorage.setItem('activeTab', key);
      setActiveKey(key);
      setCurrentPage(0);
      setTotalResults(10);
      setProductSync([]);
      setOrderSync([]);
    }
  };

  const fetchLogsSync = async () => {
    setLoading(true);
    if (activeKey === 'products') {
      const fetchedLogs = await getProductSyncLogs(resultsPerPage, currentPage);
      console.log('Fetched logs: ', fetchedLogs);
      if (fetchedLogs) {
        setProductSync(fetchedLogs.data.results);
        setTotalResults(fetchedLogs.data.total);
      }
    }

    if (activeKey === 'orders') {
      const fetchedLogs = await allOrders(resultsPerPage, currentPage);
      if (fetchedLogs) {
        setOrderSync(fetchedLogs.data.results);
        setTotalResults(fetchedLogs.data.total);
      }
    }
    setLoading(false);
  };

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    fetchLogsSync();
  }, [currentPage, resultsPerPage, activeKey]);

  return (
    <>
      <Container className="toast-b-right">
        <AnimatePresence>
          {toasts.map((toast, index) => (
            <CustomToast
              duration={3000}
              key={index}
              show={true}
              title={`Sync status for ID: ${toast.id}`}
              message={toast.message}
              type="success"
              onClose={() => removeToast(toast.id)}
            />
          ))}
        </AnimatePresence>
      </Container>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Sync overview</Card.Header>
            <Card.Body>
              <Tabs className="custom-tab" defaultActiveKey={activeKey} id="sync-overview-tabs" onSelect={(key) => handleSelectTab(key)}>
                <Tab eventKey="products" title="Products">
                  <ProductsTab productSync={productSync} loading={loading} setToasts={setToasts} fetchLogsSync={fetchLogsSync} />
                </Tab>
                <Tab eventKey="orders" title="Orders">
                  <OrdersTab orderSync={orderSync} loading={loading} setToasts={setToasts} fetchLogsSync={fetchLogsSync} />
                </Tab>
              </Tabs>
            </Card.Body>
            <Row className="mt-4">
              <Col className="d-flex justify-content-center">
                <PaginationComponent
                  itemsCount={totalResults}
                  itemsPerPage={resultsPerPage}
                  currentPage={currentPage + 1}
                  setCurrentPage={(pageChangeTo) => setCurrentPage(pageChangeTo - 1)}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
