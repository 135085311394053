import React from 'react';
import { Table, Button } from 'react-bootstrap';

import type { OrderTableComponentProps } from './types';

const OrderTableComponent: React.FC<OrderTableComponentProps> = ({ orderLogs, handleShowData }) => {
  return (
    <Table className="enhanced-table" striped responsive>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Log ID</th>
          <th>Request ID</th>
          <th>Source</th>
          <th>Type</th>
          <th>Message</th>
          <th>Status</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        {orderLogs.map((orderLog, index) => (
          <tr key={index}>
            <td>{new Date(orderLog.order_log_created).toLocaleString('da-DK')}</td>
            <td>{orderLog.order_log_id}</td>
            <td>{orderLog.order_request_id}</td>
            <td>{orderLog.order_log_source}</td>
            <td>{orderLog.order_log_source}</td>
            <td>{orderLog.order_log_message}</td>
            <td>{orderLog.order_log_status}</td>
            <td>
              <Button onClick={() => handleShowData(orderLog)}>Show data</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default OrderTableComponent;
