import React, { useState } from 'react';
import { Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';

import { getAllFoldersWithHierarchyByUserId, integrationByType, updateUserIntegration, getAllCategoriesHierarchyByUserId } from '@src/Api';

import type { RentmanUserSettings } from '@src/pages/settings/rentmanApi/types';
import type { FolderWithChildren, CategoryWithChildren } from './types';

export const CategoryMappingPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rentmanUserDefaultData, setRentmanUserDefaultData] = useState<RentmanUserSettings>({});
  const [rentmanFolders, setRentmanFolders] = useState<FolderWithChildren[]>([]);
  const [bigcommerceCategories, setBigcommerceCategories] = useState<CategoryWithChildren[]>([]);

  React.useEffect(() => {
    const getDefaultData = async () => {
      const rentmanIntegrationData = await integrationByType('rentman', 'data');
      if (rentmanIntegrationData && rentmanIntegrationData.data.users_integrations_default_data !== null) {
        setRentmanUserDefaultData(rentmanIntegrationData.data.users_integrations_default_data);
      }

      const folderData = await getAllFoldersWithHierarchyByUserId();
      if (folderData) {
        setRentmanFolders(folderData.data);
      }

      const categoryData = await getAllCategoriesHierarchyByUserId();
      if (categoryData) {
        setBigcommerceCategories(categoryData.data);
      }
    };
    getDefaultData();
  }, []);

  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      [key]: event.target.checked,
    });
  };

  const handleCategoryChange = (folderId: number, event: React.ChangeEvent<HTMLSelectElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      folder_mapping: {
        ...rentmanUserDefaultData.folder_mapping,
        [`${folderId}`]: parseInt(`${event.target.value}`),
      },
    });
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setLoading(true);

    const rentmanResponse = await updateUserIntegration('rentman', rentmanUserDefaultData);
    if (rentmanResponse) {
      setRentmanUserDefaultData(rentmanResponse.data.users_integrations_default_data);
    }

    setLoading(false);
  };

  const renderBigcommerceOptions = (category: CategoryWithChildren, parentIndex: number, itemId: number): JSX.Element => (
    <React.Fragment key={`${itemId}-${category.id}-${parentIndex}`}>
      <option key={`${itemId}-${category.id}`} value={category.id}>
        {Array.from({ length: parentIndex }).map((_, index) => {
          return <React.Fragment key={`${itemId}-${category.id}-fragment-${index}`}>&nbsp;&nbsp;</React.Fragment>;
        })}
        {category.name}
      </option>
      {category.children.length > 0 && category.children.map((child) => renderBigcommerceOptions(child, parentIndex + 1, itemId))}
    </React.Fragment>
  );

  const renderRentmanFolder = (item: FolderWithChildren): JSX.Element => (
    <li key={item.id} className="mb-4">
      <div className="folder-item">
        <span>{item.name}</span>
        <Form.Group controlId={`select-${item.id}`} className="folder-select">
          <Form.Select
            name={`bigcommerce-select-${item.id}`}
            value={(rentmanUserDefaultData.folder_mapping && rentmanUserDefaultData.folder_mapping[item.id]) || ''}
            onChange={(e) => handleCategoryChange(item.id, e)}
          >
            <option value="">Select a category</option>
            {bigcommerceCategories.map((category) => renderBigcommerceOptions(category, 0, item.id))}
          </Form.Select>
        </Form.Group>
      </div>
      {item.children.length > 0 && <ul className="folder-children mt-4">{item.children.map(renderRentmanFolder)}</ul>}
    </li>
  );

  return (
    <Row className="mt-4">
      <Col sm={12}>
        <Card>
          <Card.Header>Category Mapping</Card.Header>
          <Card.Body>
            <Form>
              <Row className="mt-4 mb-4">
                <Col md={{ span: 1, offset: 11 }}>
                  <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" className="mr-2" /> ...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Col>
              </Row>

              <Row className="mt-4 mb-4">
                <Col>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={6}>
                      Enable folder mapping from Rentman to Bigcommerce.
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Enable"
                        checked={rentmanUserDefaultData.enable_folder_mapping ? rentmanUserDefaultData.enable_folder_mapping : false}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormCheckChange(event, 'enable_folder_mapping')}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4 mb-4">
                <Col>
                  {rentmanUserDefaultData.enable_folder_mapping && rentmanUserDefaultData.enable_folder_mapping === true && (
                    <Row as="div">
                      <Col sm={6}>
                        <h4>Rentman Category</h4>
                      </Col>
                      <Col sm={6}>
                        <h4>Bigcommerce Category</h4>
                      </Col>
                      <ul className="ps-4 mt-4">{rentmanFolders.map((folder) => renderRentmanFolder(folder))}</ul>
                    </Row>
                  )}
                </Col>
              </Row>

              <Row className="mt-4 mb-4">
                <Col md={{ span: 1, offset: 11 }}>
                  <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" className="mr-2" /> ...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
