import React from 'react';
import { MailgunSettingModuleProps } from './types';
import { Col, FloatingLabel, Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

export const MailgunForm: React.FC<MailgunSettingModuleProps> = ({ mailgunUserDefaultData, setMailgunUserDefaultData }) => {
  const handleConnectionEmailEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailgunUserDefaultData((prevState) => ({
      ...prevState,
      connection_email_enabled: event.target.checked,
    }));
  };

  const handleOrderEmailEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailgunUserDefaultData((prevState) => ({
      ...prevState,
      order_email_enabled: event.target.checked,
    }));
  };

  const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailgunUserDefaultData((prevState) => ({
      ...prevState,
      email: event.target.value,
    }));
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSelectedTaxclass">
        <Form.Label column sm={6}>
          Specify recipients email(s)
        </Form.Label>
        <Col sm={6}>
          <FloatingLabel controlId="floatingInputEmail" label="Specify which email(s) to send to">
            <Form.Control
              type="text"
              aria-label="Floating label select"
              value={mailgunUserDefaultData?.email || ''}
              onChange={handleEmailChanged}
            ></Form.Control>
            <Form.Text muted>Separate multiple emails with commas</Form.Text>
          </FloatingLabel>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalConnectionEmailEnabled">
        <Form.Label column sm={6}>
          Enable recieving emails when Connection fails.
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="connection-switch"
            label="Enable"
            checked={mailgunUserDefaultData?.connection_email_enabled ? mailgunUserDefaultData?.connection_email_enabled : false}
            onChange={handleConnectionEmailEnabled}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalOrderEmailEnabled">
        <Form.Label column sm={6}>
          Enable recieving emails when Order fails
        </Form.Label>
        <Col sm={6}>
          <Form.Check
            type="switch"
            id="order-switch"
            label="Enable"
            checked={mailgunUserDefaultData?.order_email_enabled ? mailgunUserDefaultData?.order_email_enabled : false}
            onChange={handleOrderEmailEnabled}
          />
        </Col>
      </Form.Group>
    </>
  );
};
