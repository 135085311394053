import React from 'react';
import { Table, Spinner, Button } from 'react-bootstrap';

import { resyncOrder } from '@src/Api';

import type { OrderTabProps } from './types';

export const OrdersTab: React.FC<OrderTabProps> = ({ orderSync, loading, setToasts, fetchLogsSync }) => {
  const [activeOrderIds, setActiveOrderIds] = React.useState<number[]>([]);

  const handleResyncClick = async (orderId: number): Promise<void> => {
    setActiveOrderIds((prevActiveInvoiceIds) => [...prevActiveInvoiceIds, orderId]);

    const response = await resyncOrder(orderId);

    if (response?.status === 200) {
      const message = response.data.message || response.data.hint;
      setToasts((prevToasts) => [...prevToasts, { id: orderId, message }]);
      fetchLogsSync();
    }

    setActiveOrderIds((prevActiveInvoiceIds) => prevActiveInvoiceIds.filter((id) => id !== orderId));
  };

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Request ID</th>
          <th>Order ID</th>
          <th>Price</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={5} className="table-loader">
              <Spinner className="data-loading-spinner"></Spinner>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {orderSync !== undefined &&
            orderSync.length !== 0 &&
            orderSync.map((sync, index) => (
              <tr key={index}>
                <td>{new Date(sync.order_created).toLocaleString('da-DK')}</td>
                <td>{sync.order_request_id}</td>
                <td>{sync.order_id}</td>
                <td>{sync.order_price}</td>
                <td>{sync.order_status}</td>
                <td>
                  {activeOrderIds.includes(sync.order_id) ? (
                    <Spinner className="custom-spinner" animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button onClick={() => handleResyncClick(sync.order_id)}>Re-sync</Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </Table>
  );
};
